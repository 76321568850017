import backMobile from "../images/backVertical.png";
import market from "../images/coinmarketcap.png";
import gecko from "../images/coingecko.svg";
import passive from "../images/passive.svg";
import dev from "../images/dev.svg";
import burn from "../images/burn.svg";
import ecoSystemIcon from "../images/icon-ecosystem.svg";

const panelInfo = {
  hypr: {
    id: "hypr",
    backM: backMobile,
    alt: "uno",
    title: "HYPERBURN ($HYPR)",
    description:
      "A deflationary token with a burn on every transaction after buying. The burn rate is based on a curve against the price of BNB. The highest rate is 33% and the lowest rate is 0.5%.The “burn” is divided into three portions:",
    infoBotOne: "BUY $HYPR",
    infoBotTwo: "READ DOCS",
    linkOne:
      "https://hyperjump-defi.github.io/#/tokenomics/usdhypr-hyperburn-bsc",
    linkTwo: {
      bsc: "https://bsc.hyperjump.app/swap?inputCurrency=BNB&outputCurrency=0x130025eE738A66E691E6A7a62381CB33c6d9Ae83",
    },
    market: market,
    gecko: gecko,
    iconOne: passive,
    titleOne: "Passive Earning",
    descriptionOne: "50% distributed to HYPR holder wallets",
    iconTwo: dev,
    titleTwo: "Development Fund",
    descriptionTwo:
      "25% distributed to development fund for longevity of the project",
    iconThree: burn,
    titleThree: "Burned Forever",
    descriptionThree: "25% burned out of total supply forever",
  },
  aurora: {
    id: "aurora",
    backM: backMobile,
    alt: "uno",
    title: "HYPERAURORA ($AURORA)",
    description:
      "A deflationary token with a burn on every transaction after buying. The burn rate is based on a curve against the price of FTM. The highest rate is 15% and the lowest rate is 0.5%.The “burn” is divided into three portions:",
    infoBotOne: "BUY $AURORA",
    infoBotTwo: "READ DOCS",
    linkOne:
      "https://hyperjump-defi.github.io/#/tokenomics/usdaurora-hyperaurora-ftm",
    linkTwo: {
      ftm: "https://ftm.hyperjump.app/swap?inputCurrency=FTM&outputCurrency=0x78DE9326792ce1d6eCA0c978753c6953Cdeedd73",
    },
    market: market,
    gecko: gecko,
    iconOne: passive,
    titleOne: "Passive Earning",
    descriptionOne: "50% distributed to HYPR holder wallets",
    iconTwo: dev,
    titleTwo: "Development Fund",
    descriptionTwo:
      "25% distributed to development fund for longevity of the project",
    iconThree: burn,
    titleThree: "Burned Forever",
    descriptionThree: "25% burned out of total supply forever",
  },
  jump: {
    id: "jump",
    backM: backMobile,
    alt: "uno",
    title: "HYPERJUMP ($JUMP)",
    description:
      "$JUMP is multi-chain farming token created on Fantom Network and distributed to other chains via our multi-chain distributor. $JUMP has a limited total supply of 250M.",
    infoBotOne: "BUY $JUMP",
    infoBotTwo: "READ DOCS",
    linkOne: "https://hyperjump-defi.github.io/#/tokenomics/usdjump-hyperjump",
    linkTwo: {
      ftm: "https://ftm.hyperjump.app/swap?inputCurrency=FTM&outputCurrency=0x78DE9326792ce1d6eCA0c978753c6953Cdeedd73",
      bsc: "https://bsc.hyperjump.app/swap?inputCurrency=BNB&outputCurrency=0x130025eE738A66E691E6A7a62381CB33c6d9Ae83",
    },
    market: market,
    gecko: gecko,
    iconOne: passive,
    titleOne: "Slow Emission",
    descriptionOne: "Emitted at 3.14/s for all chains.",
    iconTwo: ecoSystemIcon,
    titleTwo: "MultiChain",
    descriptionTwo: "$JUMP can be bridged via HyperJump’s Bridge HyperVortex.",
    iconThree: burn,
    titleThree: "Multi-UseCase",
    descriptionThree:
      "$JUMP is featured in every dApp and has multiple usecases such as staking and betting.",
  },
};
export default panelInfo;
