import React from "react";
import { Box } from "@chakra-ui/react";
import { Header } from "./components/Header.jsx";
import { Home } from "./components/Home.jsx";
import { Dapps } from "./components/Dapps.jsx";
import { Tokens } from "./components/Tokens.jsx";
import { Hyper } from "./components/Hyper.jsx";
import { Route, Redirect } from "react-router-dom";

export function App() {
  return (
    <Box>
      <Header />
      <Route exact path="/" component={Home} />
      <Route exact path="/" component={Dapps} />
      <Route exact path="/" component={Tokens} />
      <Route exact path="/" component={Hyper} />
      <Route render={() => <Redirect to="/" />} />
    </Box>
  );
}
