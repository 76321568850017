import React, { useEffect, useState } from "react";
import { Box, Image } from "@chakra-ui/react";
import hyper from "../images/token-hypr.png";
import jump from "../images/token-jump.png";
import aurora from "../images/token-aurora.png";
import lHyper from "../images/lineHyper.png";
import lAurora from "../images/lineAurora.png";
import lJump from "../images/lineAlloy.png";

export function ButtonsPanel({ setToken }) {
  // console.log("ButtonsPanel", setToken);
  const [opacityHypr, setOpacityHypr] = useState(1.0);
  const [opacityJump, setOpacityJump] = useState(0.25);
  const [opacityAurora, setOpacityAurora] = useState(0.25);
  const [line, setLine] = useState("url(" + lHyper + ")");

  
  useEffect(() => {});

  const handleHyprClick = () => {
    setOpacityHypr(1.0);
    setOpacityAurora(0.25);
    setOpacityJump(0.25);
    setLine("url(" + lHyper + ")");
    setToken('hypr');
  };

  const handleJumpClick = () => {
    setOpacityHypr(0.25);
    setOpacityAurora(0.25);
    setOpacityJump(1.0);
    setLine("url(" + lJump + ")");
    setToken('jump');
  };

  const handleAuroraClick = () => {
    setOpacityHypr(0.25);
    setOpacityAurora(1.0);
    setOpacityJump(0.25);
    setLine("url(" + lAurora + ")");
    setToken('aurora');
  };
 


  return (
    <Box className="lineHyper" id="back" style={{ backgroundImage: line }}>
      <Box
        as="button"
        className="buttonHyper"
        id="lineHyper"
        transition="all 0.2s cubic-bezier(.08,.52,.52,1)"
        _active={{ transform: "scale(0.98)" }}
        style={{ opacity: opacityHypr }}
        onClick={handleHyprClick}
      >
        <Image src={hyper} h="120px" />
      </Box>

      <Box
        as="button"
        className="buttonJump"
        id="lineJump"
        transition="all 0.2s cubic-bezier(.08,.52,.52,1)"
        _active={{ transform: "scale(0.98)" }}
        style={{ opacity: opacityJump }}
        onClick={handleJumpClick}
      >
        <Image src={jump} h="120px" />
      </Box>

      <Box
        as="button"
        className="buttonAurora"
        id="lineAurora"
        transition="all 0.2s cubic-bezier(.08,.52,.52,1)"
        _active={{ transform: "scale(0.98)" }}
        style={{ opacity: opacityAurora }}
        onClick={handleAuroraClick}
      >
        <Image src={aurora} h="120px" />
      </Box>
    </Box>
  );
}
