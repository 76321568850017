import React from "react";
import slide1 from "../slider/dapps.jpg";
import slide2 from "../slider/dapps2.jpg";
import slide3 from "../slider/dapps3.jpg";
import slide4 from "../slider/dapps4.jpg";
import slide5 from "../slider/dapps5.jpg";
import slide6 from "../slider/dapps6.jpg";
import slide1M from "../slider/sliderMobile_1.jpg";
import slide2M from "../slider/sliderMobile_2.jpg";
import slide3M from "../slider/sliderMobile_3.jpg";
import slide4M from "../slider/sliderMobile_4.jpg";
import slide5M from "../slider/sliderMobile_5.jpg";
import slide6M from "../slider/sliderMobile_6.jpg";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Box } from "@chakra-ui/layout";
import { Button } from "@chakra-ui/react";

export function BackDapps() {
  return (
    <Box>
      {/* Desktop */}
      <Box display={["none", "none", "flex", "flex"]}>
        <Carousel
          className="boxSlider"
          autoPlay={true}
          infiniteLoop={true}
          showThumbs={false}
          showArrows={false}
          showStatus={false}
          axis={"horizontal"}
          swipeable={false}
          emulateTouch={false}
        >
          <div>
            <img alt="Hyperswap" src={slide1} />
            <Box className="legend">
              <h1>HYPERSWAP</h1>
              <p>
                Trade your Assets at hyper speed with HyperSwap! HyperSwap is an
                automated market maker (AMM) that allows you to swap between 2
                tokens on the Binance Smart Chain or Fantom Opera Chain.
              </p>
              <Box display="flex">
                <Button
                  as="a"
                  href="https://bsc.hyperjump.app/swap?inputCurrency=BNB&outputCurrency=0x130025eE738A66E691E6A7a62381CB33c6d9Ae83"
                  target="_blank"
                  height="32px"
                  fontSize="16px"
                  colorScheme="blue"
                  className="buttonLengend"
                >
                  LAUNCH ON BSC
                </Button>
                <Button
                  as="a"
                  target="_blank"
                  href="https://ftm.hyperjump.app/swap?inputCurrency=FTM&outputCurrency=0x78DE9326792ce1d6eCA0c978753c6953Cdeedd73"
                  height="32px"
                  fontSize="16px"
                  colorScheme="blue"
                  className="buttonLengend"
                >
                  LAUNCH ON FANTOM
                </Button>
              </Box>
            </Box>
          </div>
          <div>
            <img alt="The Asteroid Field" src={slide2} />
            <Box className="legend">
              <h1>THE ASTEROID FIELD</h1>
              <p>
                Welcome to the Asteroid Field, cadet! Deposit LP token to mine
                HyperJump token ($JUMP).
              </p>
              <Box display="flex">
                <Button
                  as="a"
                  target="_blank"
                  href="https://bsc.hyperjump.app/farms"
                  height="32px"
                  fontSize="16px"
                  colorScheme="blue"
                  className="buttonLengend"
                >
                  LAUNCH ON BSC
                </Button>
                <Button
                  as="a"
                  target="_blank"
                  href="https://ftm.hyperjump.app/farms"
                  height="32px"
                  fontSize="16px"
                  colorScheme="blue"
                  className="buttonLengend"
                >
                  LAUNCH ON FANTOM
                </Button>
              </Box>
            </Box>
          </div>
          <div>
            <img alt="Mech Workshop" src={slide3} />
            <Box className="legend">
              <h1>MECH WORKSHOP</h1>
              <p>
                Let's build stake $JUMP in the Mech Workshop. Staking $JUMP will
                earn you more $JUMP or other tokens along the way.
              </p>
              <Box display="flex">
                <Button
                  as="a"
                  target="_blank"
                  href="https://bsc.hyperjump.app/pools"
                  height="32px"
                  fontSize="16px"
                  colorScheme="blue"
                  className="buttonLengend"
                >
                  LAUNCH ON BSC
                </Button>
                <Button
                  as="a"
                  target="_blank"
                  href="https://ftm.hyperjump.app/pools"
                  height="32px"
                  fontSize="16px"
                  colorScheme="blue"
                  className="buttonLengend"
                >
                  LAUNCH ON FANTOM
                </Button>
              </Box>
            </Box>
          </div>
          <div>
            <img alt="Star Vaults" src={slide4} />
            <Box className="legend">
              <h1>STAR VAULTS</h1>
              <p>
                Star Vaults are auto compounding vaults. Deposit LPs or tokens
                and the vaults will automatically farm the asset and add to the
                deposited balance.
              </p>
              <Box display="flex">
                <Button
                  as="a"
                  target="_blank"
                  href="https://bsc.hyperjump.app/vaults"
                  height="32px"
                  fontSize="16px"
                  colorScheme="blue"
                  className="buttonLengend"
                >
                  LAUNCH ON BSC
                </Button>
                <Button
                  as="a"
                  target="_blank"
                  href="https://ftm.hyperjump.app/vaults"
                  height="32px"
                  fontSize="16px"
                  colorScheme="blue"
                  className="buttonLengend"
                >
                  LAUNCH ON FANTOM
                </Button>
              </Box>
            </Box>
          </div>
          <div>
            <img alt="Unrekt" src={slide5} />
            <Box className="legend">
              <h1>UNREKT</h1>
              <p>
                Find & revoke all the addresses that can spend your tokens! Keep
                your Space Ship safe from thieves on both Binance Smart Chain
                and Fantom Opera.
              </p>
              <Box display="flex">
                <Button
                  as="a"
                  target="_blank"
                  href="https://unrekt.hyperjump.fi/"
                  height="32px"
                  fontSize="16px"
                  colorScheme="blue"
                  className="buttonLengend"
                >
                  LAUNCH ON BSC
                </Button>
                <Button
                  as="a"
                  target="_blank"
                  href="https://unrekt.hyperjump.fi"
                  height="32px"
                  fontSize="16px"
                  colorScheme="blue"
                  className="buttonLengend"
                >
                  LAUNCH ON FANTOM
                </Button>
              </Box>
            </Box>
          </div>
          <div>
            <img alt="Unrekt" src={slide6} />
            <Box className="legend">
              <h1>CASINO</h1>
              <p>
                MoonBet Casino is a product created to provide seamless, fun,
                profitable gaming experience to HyperJump finance users.
              </p>
              <Box display="flex">
                <Button
                  as="a"
                  target="_blank"
                  href="https://play.hyperjump.app/"
                  height="32px"
                  fontSize="16px"
                  colorScheme="blue"
                  className="buttonLengend"
                >
                  LAUNCH CASINO
                </Button>
              </Box>
            </Box>
          </div>
        </Carousel>
      </Box>

      {/* End Desktop */}

      {/* Mobile */}
      <Box display={["flex", "flex", "none", "none"]}>
        <Carousel
          className="boxSlider"
          autoPlay={true}
          infiniteLoop={true}
          showThumbs={false}
          showArrows={false}
          showStatus={false}
          swipeable={false}
          emulateTouch={false}
          verticalSwipe={"natural"}
        >
          <div>
            <img alt="Hyperswap" src={slide1M} />
            <Box className="legend">
              <h1>HYPERSWAP</h1>
              <p>
                Trade your Assets at hyper speed with HyperSwap! HyperSwap is an
                automated market maker (AMM) that allows you to swap between 2
                tokens on the Binance Smart Chain or Fantom Opera Chain.
              </p>
              <Box display="flex">
                <Button
                  as="a"
                  target="_blank"
                  href="https://bsc.hyperjump.app/swap?inputCurrency=BNB&outputCurrency=0x130025eE738A66E691E6A7a62381CB33c6d9Ae83"
                  height="29px"
                  fontSize="13px"
                  colorScheme="blue"
                  className="buttonLengend"
                >
                  LAUNCH ON BSC
                </Button>
                <Button
                  as="a"
                  target="_blank"
                  href="https://ftm.hyperjump.app/swap?inputCurrency=FTM&outputCurrency=0x78DE9326792ce1d6eCA0c978753c6953Cdeedd73"
                  height="29px"
                  fontSize="13px"
                  colorScheme="blue"
                  className="buttonLengend"
                >
                  LAUNCH ON FANTOM
                </Button>
              </Box>
            </Box>
          </div>
          <div>
            <img alt="The asteroid field" src={slide2M} />
            <Box className="legend">
              <h1>THE ASTEROID FIELD</h1>
              <p>
                Welcome to the Asteroid Field, cadet! Deposit LP token to mine
                HyperJump token ($JUMP).
              </p>
              <Box display="flex">
                <Button
                  as="a"
                  target="_blank"
                  href="https://bsc.hyperjump.app/farms"
                  height="29px"
                  fontSize="13px"
                  colorScheme="blue"
                  className="buttonLengend"
                >
                  LAUNCH ON BSC
                </Button>
                <Button
                  as="a"
                  target="_blank"
                  href="https://ftm.hyperjump.app/farms"
                  height="29px"
                  fontSize="13px"
                  colorScheme="blue"
                  className="buttonLengend"
                >
                  LAUNCH ON FANTOM
                </Button>
              </Box>
            </Box>
          </div>
          <div>
            <img alt="Mech Workshop" src={slide3M} />
            <Box className="legend">
              <h1>MECH WORKSHOP</h1>
              <p>
                Let's build stake $JUMP in the Mech Workshop. Staking $JUMP will
                earn you more $JUMP or other tokens along the way.
              </p>
              <Box display="flex">
                <Button
                  as="a"
                  target="_blank"
                  href="https://bsc.hyperjump.app/pools"
                  height="29px"
                  fontSize="13px"
                  colorScheme="blue"
                  className="buttonLengend"
                >
                  LAUNCH ON BSC
                </Button>
                <Button
                  as="a"
                  target="_blank"
                  href="https://ftm.hyperjump.app/pools"
                  height="29px"
                  fontSize="13px"
                  colorScheme="blue"
                  className="buttonLengend"
                >
                  LAUNCH ON FANTOM
                </Button>
              </Box>
            </Box>
          </div>
          <div>
            <img alt="Start Vaults" src={slide4M} />
            <Box className="legend">
              <h1>STAR VAULTS</h1>
              <p>
                Star Vaults are auto compounding vaults. Deposit LPs or tokens
                and the vaults will automatically farm the asset and add to the
                deposited balance.
              </p>
              <Box display="flex">
                <Button
                  as="a"
                  target="_blank"
                  href="https://bsc.hyperjump.app/vaults"
                  height="29px"
                  fontSize="13px"
                  colorScheme="blue"
                  className="buttonLengend"
                >
                  LAUNCH ON BSC
                </Button>
                <Button
                  as="a"
                  target="_blank"
                  href="https://ftm.hyperjump.app/vaults"
                  height="29px"
                  fontSize="13px"
                  colorScheme="blue"
                  className="buttonLengend"
                >
                  LAUNCH ON FANTOM
                </Button>
              </Box>
            </Box>
          </div>
          <div>
            <img alt="Unrekt" src={slide5M} />
            <Box className="legend">
              <h1>UNREKT</h1>
              <p>
                Find & revoke all the addresses that can spend your tokens! Keep
                your Space Ship safe from thieves on both Binance Smart Chain
                and Fantom Opera.
              </p>
              <Box display="flex">
                <Button
                  as="a"
                  target="_blank"
                  href="https://unrekt.hyperjump.fi/"
                  height="29px"
                  fontSize="13px"
                  colorScheme="blue"
                  className="buttonLengend"
                >
                  LAUNCH ON BSC
                </Button>
                <Button
                  as="a"
                  target="_blank"
                  href="https://unrekt.hyperjump.fi/"
                  height="29px"
                  fontSize="13px"
                  colorScheme="blue"
                  className="buttonLengend"
                >
                  LAUNCH ON FANTOM
                </Button>
              </Box>
            </Box>
          </div>
          <div>
            <img alt="Unrekt" src={slide6M} />
            <Box className="legend">
              <h1>CASINO</h1>
              <p>
                MoonBet Casino is a product created to provide seamless, fun,
                profitable gaming experience to HyperJump finance users.
              </p>
              <Box display="flex">
                <Button
                  as="a"
                  target="_blank"
                  href="https://play.hyperjump.app/"
                  height="32px"
                  fontSize="16px"
                  colorScheme="blue"
                  className="buttonLengend"
                >
                  LAUNCH CASINO
                </Button>
              </Box>
            </Box>
          </div>
        </Carousel>
      </Box>

      {/* End Mobile */}
    </Box>
  );
}
