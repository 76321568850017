import React from "react";
import { Box, Flex, Image } from "@chakra-ui/react";
import headerDapps from "../images/headerDapps.svg";
import headerDappsMobile from "../images/dapps-titleMobile.svg";

export function HeaderDapps() {
  return (
    <Flex className="headerDapps">
      <Box
        w="100%"
        bg="brand.accent"
        h="5px"
        top="10px"
        pos="relative"
        zIndex={1}
      ></Box>

      <Box
        w="100%"
        pos="absolute"
        zIndex={1}
      >
        <Box display={["none", "none", "flex", "flex" ]}> 
        <Image src={headerDapps} className="backDapps" />
        </Box>

        <Box display={["flex", "flex", "none", "none" ]}> 
        <Image src={headerDappsMobile} className="backDapps" />
        </Box>

      </Box>
    </Flex>
  );
}
