import React from "react";
import { HeaderDapps } from "./HeaderDapps.jsx";
import { BackDapps } from "./BackDapps.jsx";
import { Box } from "@chakra-ui/react";

export function Dapps() {
  return (
    <Box
      h="100%"
      id="dapps"
      bg="brand.backDark"
      display="flex"
      minHeight="768"
      alignItems="center"
      position="relative"
     
    >
      <HeaderDapps />
      <BackDapps />
    </Box>
  );
}
