import React from "react";
import { Box, Image, Flex } from "@chakra-ui/react";
import titleTokens from "../images/titleTokens.svg";
import titleTokensMobile from "../images/titleTokensMobile.svg";

export function TitleTokens() {
  return (
    <Flex className="titleTokens"> 
      <Box w="100%" pos="absolute" zIndex={1}>
        <Image src={titleTokens} className="imageTokens" display={["none", "none", "flex", "flex" ]} />
      </Box>

      <Box w="100%" pos="absolute" zIndex={1} display={["flex", "flex", "none", "none" ]}>
        <Image src={titleTokensMobile} className="imageTokens"  />
      </Box>
    </Flex>
  );
}
