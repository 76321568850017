import React from "react";
import { BackHome } from "./BackHome.jsx";
import { FooterHome } from "./FooterHome.jsx";
import { Box } from "@chakra-ui/react";

export function Home() {
  return (
    <Box h="100vh" id="home" position="relative">
      <BackHome />
      <Box >
         <FooterHome /> 
      </Box>
    </Box>
  );
}
