import { ChakraProvider } from "@chakra-ui/react";
import React from "react";
import ReactDom from "react-dom";
import { App } from "./App.jsx";
import './styles.css';
import { theme } from "./resources/theme/colores.js";
import { BrowserRouter } from 'react-router-dom';



ReactDom.render(

    <ChakraProvider theme={theme}>

        <React.StrictMode>
        <BrowserRouter>
            <App />
            </BrowserRouter>
        </React.StrictMode>

    </ChakraProvider>,
    
    document.getElementById("root")
    
    );



