import React from "react";
import { Box, Flex, Image } from "@chakra-ui/react";
import titleHyper from "../images/whyHyper.svg";
import titleHyperMobile from "../images/whyHyperMobile.svg";

export function TitleHyper() {
  return (
    <Flex>
      <Box
        w="100%"
        top="20px"
        zIndex={1}
        display={["none", "none", "flex", "flex"]}
      >
        <Image src={titleHyper} className="titleHyper" />
      </Box>
      <Box
        w="100%"
        top="40px"
        zIndex={1}
        display={["flex", "flex", "none", "none"]}
      >
        <Image src={titleHyperMobile} className="titleHyper" />
      </Box>
    </Flex>
  );
}
