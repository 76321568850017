import React, { useEffect, useState } from "react";
import { Box, Image } from "@chakra-ui/react";
import hyper from "../images/token-hypr.png";
import jump from "../images/token-jump.png";
import aurora from "../images/token-aurora.png";


export function ButtonsPanelMobile({ setToken }) {
  // console.log("ButtonsPanelMobile", setToken);
  const [opacityHypr, setOpacityHypr] = useState(1.0);
  const [opacityJump, setOpacityJump] = useState(0.25);
  const [opacityAurora, setOpacityAurora] = useState(0.25);


  useEffect(() => {}); 

  const handleHyprClick = () => {
    setOpacityHypr(1.0);
    setOpacityAurora(0.25);
    setOpacityJump(0.25);
    setToken('hypr');
  };

  const handleJumpClick = () => {
    setOpacityHypr(0.25);
    setOpacityAurora(0.25);
    setOpacityJump(1.0);
    setToken('jump');
  };

  const handleAuroraClick = () => {
    setOpacityHypr(0.25);
    setOpacityAurora(1.0);
    setOpacityJump(0.25);
    setToken('aurora');
  };
 


  return (
    <Box className="buttonsMobile">
      <Box
        as="button"
        className=""
        transition="all 0.2s cubic-bezier(.08,.52,.52,1)"
        _active={{ transform: "scale(0.98)" }}
        style={{ opacity: opacityHypr }}
        onClick={handleHyprClick}
      >
        <Image src={hyper} h="60px" m="0 20px" />
      </Box>

      <Box
        as="button"
        transition="all 0.2s cubic-bezier(.08,.52,.52,1)"
        _active={{ transform: "scale(0.98)" }}
        style={{ opacity: opacityJump }}
        onClick={handleJumpClick}
      >
        <Image src={jump} h="60px" m="0 20px"/>
      </Box>

      <Box
        as="button"
        transition="all 0.2s cubic-bezier(.08,.52,.52,1)"
        _active={{ transform: "scale(0.98)" }}
        style={{ opacity: opacityAurora }}
        onClick={handleAuroraClick}
      >
        <Image src={aurora} h="60px" m="0 20px"/>
      </Box>
    </Box>
  );
}
