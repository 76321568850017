import { Box } from "@chakra-ui/react";
import React from "react";
import { Menu, Link, MenuIcon } from "@chakra-ui/react";
import { Flex, Text, Spacer } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDiscord,
  faGithub,
  faMediumM,
  faYoutube,
  faInstagram,
  faTwitter,
  faTelegramPlane,
  faFacebookSquare,
} from "@fortawesome/free-brands-svg-icons";
import axios from "axios";

// We need to get tvl data from ALL chains.
// at the moment bsc and ftm
// the apis can fail, so we must account for a null response
// so it is probably better to make a function that gets both and then sets the TVL

axios.get("https://api.hyperjump.app/tvl").then((response) => {
  // console.log(response);
  if (response.data.tvl !== null) {
    document.getElementById("vault").innerHTML = response.data.tvl.toFixed(0);
  } else {
    document.getElementById("vault").innerHTML = "Loading...";
  }
});

export function FooterHome() {
  return (
    <Box>
      <Box className="footerHome" display={["none", "none", "flex", "flex"]}>
        <Box w="100%" color="white">
          <Box>
            <Flex w="100%">
              <Text
                w="100%"
                h="80px"
                p="0 2rem"
                maxW="550px"
                className="textoFooter"
              >
                HyperJump is a DeFi protocol with a fun token ecosystem on
                Binance Smart Chain and Fantom.
              </Text>
            </Flex>
            <Spacer />
          </Box>

          <Flex w="100%" className="lineaHome">
            <Box w="50%">
              <Box className="tlvHome" w="484px">
                <Box className="tlvInfo">
                  <Text className="textoTlvOne">
                    {" "}
                    Cross <br />
                    <span>Chain</span>
                  </Text>
                  <Text className="textoTlvTwo"> tlv</Text>
                  <Text className="textoTlvThree" id="vault">
                    {"Loading..."}
                  </Text>
                </Box>
              </Box>
            </Box>
            <Spacer />
            <Box w="50%">
              <Box className="redesHome" w="479px">
                <Box className="tlvRedesInfo">
                  <Menu>
                    <MenuIcon className="circulo">
                      <Link href="http://t.me/HyperJump_fi" isExternal>
                        <FontAwesomeIcon icon={faTelegramPlane} />
                      </Link>
                    </MenuIcon>

                    <MenuIcon className="circulo">
                      <Link href="https://twitter.com/HyperJump_fi" isExternal>
                        <FontAwesomeIcon icon={faTwitter} />
                      </Link>
                    </MenuIcon>

                    <MenuIcon className="circulo">
                      <Link href="http://instagram.com/hyperjump_fi" isExternal>
                        <FontAwesomeIcon icon={faInstagram} />
                      </Link>
                    </MenuIcon>

                    <MenuIcon className="circulo">
                      <Link
                        href="https://www.youtube.com/channel/UCHnEgo8zv2r-a2KYZtb2_9w"
                        isExternal
                      >
                        <FontAwesomeIcon icon={faYoutube} />
                      </Link>
                    </MenuIcon>

                    <MenuIcon className="circulo">
                      <Link href="https://hyperjumpfi.medium.com/" isExternal>
                        <FontAwesomeIcon icon={faMediumM} />
                      </Link>
                    </MenuIcon>

                    <MenuIcon className="circulo">
                      <Link href="https://github.com/HyperJump-DeFi" isExternal>
                        <FontAwesomeIcon icon={faGithub} />
                      </Link>
                    </MenuIcon>

                    <MenuIcon className="circulo">
                      <Link href="https://discord.gg/YdeBdGTxY8" isExternal>
                        <FontAwesomeIcon icon={faDiscord} />
                      </Link>
                    </MenuIcon>
                    <MenuIcon className="circulo">
                      <Link href="http://facebook.com/HyperJump.fi/" isExternal>
                        <FontAwesomeIcon icon={faFacebookSquare} />
                      </Link>
                    </MenuIcon>
                  </Menu>
                </Box>
              </Box>
            </Box>
          </Flex>
        </Box>
      </Box>

      {/* //Mobile */}

      <Box
        className="footerHomeMobile"
        display={["flex", "flex", "none", "none"]}
      >
        <Box w="100%" color="white" display="flex" flexDirection="column">
          <Flex w="100%">
            <Text w="100%" className="textoFooterMobile">
              HyperJump is a DeFi protocol with a fun token ecosystem on Binance
              Smart Chain and Fantom.
            </Text>
          </Flex>
          <Spacer />

          <Flex w="100%" className="lineaHomeMobile">
            <Flex w="100%">
              <Box className="tlvHomeMobile">
                <Box className="tlvInfoMobile">
                  <Text className="textoTlvOneMobile">
                    {" "}
                    Cross <br />
                    <span>Chain</span>
                  </Text>
                  <Text className="textoTlvTwoMobile"> tlv</Text>
                  <Text className="textoTlvThreeMobile" id="vault">
                    {"Loading..."}
                  </Text>
                </Box>
              </Box>
            </Flex>
            <Spacer />
            <Box w="100%">
              <Box className="redesHomeMobile">
                <Box className="tlvRedesInfoMobile">
                  <Menu>
                    <MenuIcon className="circuloMobile">
                      <Link href="https://t.me/hyperjump_fi" isExternal>
                        {" "}
                        <FontAwesomeIcon icon={faTelegramPlane} />
                      </Link>
                    </MenuIcon>

                    <MenuIcon className="circuloMobile">
                      <Link href="https://twitter.com/HyperJump_fi" isExternal>
                        {" "}
                        <FontAwesomeIcon icon={faTwitter} />
                      </Link>
                    </MenuIcon>

                    <MenuIcon className="circuloMobile">
                      <Link
                        href="https://www.instagram.com/hyperjump_fi/"
                        isExternal
                      >
                        {" "}
                        <FontAwesomeIcon icon={faInstagram} />
                      </Link>
                    </MenuIcon>

                    <MenuIcon className="circuloMobile">
                      <Link
                        href="https://www.youtube.com/channel/UCHnEgo8zv2r-a2KYZtb2_9w"
                        isExternal
                      >
                        {" "}
                        <FontAwesomeIcon icon={faYoutube} />
                      </Link>
                    </MenuIcon>

                    <MenuIcon className="circuloMobile">
                      <Link href="https://hyperjumpbsc.medium.com/" isExternal>
                        {" "}
                        <FontAwesomeIcon icon={faMediumM} />
                      </Link>
                    </MenuIcon>

                    <MenuIcon className="circuloMobile">
                      <Link href="https://github.com/HyperJump-DeFi" isExternal>
                        {" "}
                        <FontAwesomeIcon icon={faGithub} />
                      </Link>
                    </MenuIcon>

                    <MenuIcon className="circuloMobile">
                      <Link href="https://discord.gg/3sJryfwKzU" isExternal>
                        {" "}
                        <FontAwesomeIcon icon={faDiscord} />
                      </Link>
                    </MenuIcon>
                    <MenuIcon className="circuloMobile">
                      <Link
                        href=" https://facebook.com/hyperjump.fi"
                        isExternal
                      >
                        {" "}
                        <FontAwesomeIcon icon={faFacebookSquare} />
                      </Link>
                    </MenuIcon>
                  </Menu>
                </Box>
              </Box>
            </Box>
          </Flex>
        </Box>
      </Box>
    </Box>
  );
}
