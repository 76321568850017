import React, { useState, useEffect } from "react";
import logoHeader from "../images/logoHeaderDesktop.png";
import logoHeaderMobile from "../images/logoMobile.png";
import logoBsc from "../images/logo-bsc.svg";
import logoFantom from "../images/logo-fantom.svg";
import logoMetis from "../images/logo-metis.svg";
import logoBscMobile from "../images/logo-bsc_mobile.svg";
import logoFantomMobile from "../images/logo-fantom_mobile.svg";
import logoMetisMobile from "../images/logo-metis-mobile.svg";
import lineBlue from "../images/lineBlue.png";
import lineBlueMobile from "../images/plecaBlueMobile.png";
import { Box, Flex, Center, Image, Menu, IconButton } from "@chakra-ui/react";
import { NavHashLink, HashLink as Link } from "react-router-hash-link";
import { HamburgerIcon, CloseIcon } from "@chakra-ui/icons";
import { ethers } from "ethers";

const scrollWithOffset = (el) => {
  const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
  const yOffset = -70;
  window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
};

export const Header = () => {
  const ftmButNowLink =
    "https://ftm.hyperjump.app/swap?inputCurrency=FTM&outputCurrency=0x78DE9326792ce1d6eCA0c978753c6953Cdeedd73";
  const bscBuyNowLink =
    "https://bsc.hyperjump.app/swap?inputCurrency=BNB&outputCurrency=0x130025eE738A66E691E6A7a62381CB33c6d9Ae83";
  const [display, changeDisplay] = useState("none");
  const [chainId, setChainId] = useState(250);
  const buyNowLink = chainId === 250 ? ftmButNowLink : bscBuyNowLink;

  // set chainId
  useEffect(() => {
    async function getNetwork() {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const { chainId } = await provider.getNetwork();
      setChainId(chainId);
    }

    getNetwork();
  }, [chainId]);

  return (
    /* Desktop */

    <Flex>
      <Flex>
        <Flex className="header" display={["none", "none", "none", "flex"]}>
          <Box bg="brand.accent" w="100%" className="lineRed"></Box>

          <Box as="button" className="logoHeader" w="10%">
            <Link smooth to="#home">
              <Image src={logoHeader} />
            </Link>
          </Box>

          <Flex className="chooser" w="30%">
            <Center w="100%">
              <a
                href="https://bsc.hyperjump.app/"
                target="_blank"
                rel="noreferrer"
              >
                <Box w="100%" h="7">
                  <Image src={logoBsc} className="bsc" />
                </Box>
              </a>
            </Center>
            <Center w="100%">
              <a
                href="https://ftm.hyperjump.app/"
                target="_blank"
                rel="noreferrer"
              >
                <Box w="100%" h="7">
                  <Image src={logoFantom} className="fantom" />
                </Box>
              </a>
            </Center>
            <Center w="100%">
              <a
                href="https://metis.hyperjump.app/"
                target="_blank"
                rel="noreferrer"
              >
                <Box w="100%" h="7">
                  <Image src={logoMetis} className="metis" />
                </Box>
              </a>
            </Center>
          </Flex>

          <Box className="menu" w="38%">
            <Menu style={{ textDecoration: "none" }}>
              <nav>
                <ul>
                  <li>
                    <NavHashLink
                      activeStyle={{ color: "#A62B30" }}
                      activeClassName="selected"
                      smooth
                      to="#dapps"
                      scroll={(el) => scrollWithOffset(el)}
                    >
                      dApps
                    </NavHashLink>
                  </li>

                  <li>
                    <NavHashLink
                      activeStyle={{ color: "#A62B30" }}
                      smooth
                      to="#tokens"
                      scroll={(el) => scrollWithOffset(el)}
                    >
                      Tokens
                    </NavHashLink>
                  </li>

                  <li>
                    <NavHashLink
                      activeStyle={{ color: "#A62B30" }}
                      smooth
                      to="#hyper"
                      scroll={(el) => scrollWithOffset(el)}
                    >
                      Why HyperJump?
                    </NavHashLink>
                  </li>
                </ul>
              </nav>
              <Image src={lineBlue} className="lineBlue" />
            </Menu>
          </Box>
          <a href={buyNowLink} target="_blank" rel="noreferrer">
            <Box as="button" className="buy" w="10%" />
          </a>
        </Flex>
      </Flex>

      {/* //Mobile //*/}
      <Flex className="headerMobile" display={["flex", "flex", "flex", "none"]}>
        <IconButton
          aria-label="Open Menu"
          size="lg"
          mr={2}
          bgColor="transparent"
          _hover={{ bg: "transparent" }}
          _active={{ bg: "transparent" }}
          _focus={{ borderColor: "transparent" }}
          icon={<HamburgerIcon color="brand.primary" w="45px" />}
          onClick={() => changeDisplay("flex")}
          display={["flex", "flex", "flex", "none"]}
          height="57px"
        />
        <Box
          bg="brand.accent"
          w="100%"
          height="5px"
          position="absolute"
          top="0"
        ></Box>

        <Box as="button" className="logoHeaderMobile">
          <Link smooth to="#home">
            {" "}
            <Image src={logoHeaderMobile} />
          </Link>
        </Box>

        <Flex
          className="chooserMobile"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Center w="100%">
            <a
              href="https://bsc.hyperjump.app/"
              target="_blank"
              rel="noreferrer"
            >
              <Box w="100%" h="7">
                <Image src={logoBscMobile} className="bscMobile" />
              </Box>
            </a>
          </Center>
          <Center w="100%">
            <a
              href="https://ftm.hyperjump.app/"
              target="_blank"
              rel="noreferrer"
            >
              <Box w="100%" h="7">
                <Image src={logoFantomMobile} className="fantomMobile" />
              </Box>
            </a>
          </Center>
          <Center w="100%">
            <a
              href="https://metis.hyperjump.app/"
              target="_blank"
              rel="noreferrer"
            >
              <Box w="100%" h="7">
                <Image src={logoMetisMobile} className="metisMobile" />
              </Box>
            </a>
          </Center>
        </Flex>
        <a href={buyNowLink} target="_blank" rel="noreferrer">
          <Box as="button" className="buyMobile"></Box>
        </a>

        <Image src={lineBlueMobile} className="lineBlueMobile" />

        <Flex
          w="100vw"
          display={display}
          bgColor="#000000"
          zIndex={20}
          h="57px"
          pos="fixed"
          top="5px"
          left="0"
          overflowY="auto"
          flexDir="column"
        >
          <Flex align="center">
            <Flex>
              <IconButton
                mr={2}
                bgColor="#000000"
                _hover={{ bg: "#000000" }}
                _active={{ bg: "#000000" }}
                _focus={{ borderColor: "white" }}
                height="50px"
                aria-label="Open Menu"
                size="lg"
                icon={<CloseIcon color="brand.primary" />}
                onClick={() => changeDisplay("none")}
              />
            </Flex>
            <Box className="menuMobile">
              <Menu style={{ textDecoration: "none" }}>
                <nav>
                  <ul>
                    <li>
                      <NavHashLink
                        activeStyle={{ color: "#A62B30" }}
                        activeClassName="selected"
                        smooth
                        to="#dapps"
                        scroll={(el) => scrollWithOffset(el)}
                      >
                        dApps
                      </NavHashLink>
                    </li>

                    <li>
                      <NavHashLink
                        activeStyle={{ color: "#A62B30" }}
                        smooth
                        to="#tokens"
                        scroll={(el) => scrollWithOffset(el)}
                      >
                        Tokens
                      </NavHashLink>
                    </li>

                    <li>
                      <NavHashLink
                        activeStyle={{ color: "#A62B30" }}
                        smooth
                        to="#hyper"
                        scroll={(el) => scrollWithOffset(el)}
                      >
                        Why HyperJump?
                      </NavHashLink>
                    </li>
                  </ul>
                </nav>
              </Menu>
            </Box>
          </Flex>
        </Flex>
      </Flex>
      {/* End mobile */}
    </Flex>
  );
};
