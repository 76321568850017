import React, { useState } from "react";
import { Flex, Box, Image, Button } from "@chakra-ui/react";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import ecosystem from "../images/icon-ecosystem.svg";
import safu from "../images/icon-safu.svg";
import multi from "../images/icon-multichain.svg";
import community from "../images/icon-community.svg";
import gaming from "../images/icon-gaming.svg";
import partnership from "../images/icon-partnership.svg";
// import { ethers } from "ethers";

export function SelectionsMobile() {
  const [opacityOne, setOpacityOne] = useState(1.0);
  const [opacityTwo, setOpacityTwo] = useState(0.25);
  const [opacityThree, setOpacityThree] = useState(0.25);
  const [opacityFour, setOpacityFour] = useState(0.25);
  const [opacityFive, setOpacityFive] = useState(0.25);
  const [opacitySix, setOpacitySix] = useState(0.25);
  const [opacityOneText, setOpacityOneText] = useState(1.0);
  const [opacityTwoText, setOpacityTwoText] = useState(0);
  const [opacityThreeText, setOpacityThreeText] = useState(0);
  const [opacityFourText, setOpacityFourText] = useState(0);
  const [opacityFiveText, setOpacityFiveText] = useState(0);
  const [opacitySixText, setOpacitySixText] = useState(0);
  const [item, setInfo] = useState(
    "Hyperjump has a whole ecosystem in one place. AMM, Farm, Staking, Vaults, Analytics, Permission Revoke Tool and Games."
  );

  /*  const [chainId, setChainId] = useState(250);

  useEffect(() => {
    async function getNetwork() {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const { chainId } = await provider.getNetwork();
      setChainId(chainId);
    }

    getNetwork();
  }, [chainId]);
 */
  /* 
  const appLink =
    chainId && chainId === 56
      ? "https://bsc.hyperjump.app/"
      : "https://ftm.hyperjump.app/";
 */

  const handleOneClick = () => {
    setOpacityOne(1.0);
    setOpacityTwo(0.25);
    setOpacityThree(0.25);
    setOpacityFour(0.25);
    setOpacityFive(0.25);
    setOpacitySix(0.25);
    setOpacityOneText(1.0);
    setOpacityTwoText(0);
    setOpacityThreeText(0);
    setOpacityFourText(0);
    setOpacityFiveText(0);
    setOpacitySixText(0);

    setInfo(
      "Hyperjump has a whole ecosystem in one place. AMM, Farm, Staking, Vaults, Analytics, Permission Revoke Tool and Games."
    );
  };

  const handleTwoClick = () => {
    setOpacityOne(0.25);
    setOpacityTwo(1.0);
    setOpacityThree(0.25);
    setOpacityFour(0.25);
    setOpacityFive(0.25);
    setOpacitySix(0.25);
    setOpacityOneText(0);
    setOpacityTwoText(1.0);
    setOpacityThreeText(0);
    setOpacityFourText(0);
    setOpacityFiveText(0);
    setOpacitySixText(0);
    setInfo(
      "HypeJump is Audited by Certik. Hyperjump also have its own auditing services called HyperCheck. Hyperswap is safe from Flash-Loan Exploits. Hyperjump is the first project to patch syrup bug."
    );
  };

  const handleThreeClick = () => {
    setOpacityOne(0.25);
    setOpacityTwo(0.25);
    setOpacityThree(1.0);
    setOpacityFour(0.25);
    setOpacityFive(0.25);
    setOpacitySix(0.25);
    setOpacityOneText(0);
    setOpacityTwoText(0);
    setOpacityThreeText(1.0);
    setOpacityFourText(0);
    setOpacityFiveText(0);
    setOpacitySixText(0);
    setInfo(
      "HyperJump is a MultiChain Project. We are live on Binance Smart Chain and Fantom Opera Chain. We will expand to more chains in the future."
    );
  };

  const handleFourClick = () => {
    setOpacityOne(0.25);
    setOpacityTwo(0.25);
    setOpacityThree(0.25);
    setOpacityFour(1.0);
    setOpacityFive(0.25);
    setOpacitySix(0.25);
    setOpacityOneText(0);
    setOpacityTwoText(0);
    setOpacityThreeText(0);
    setOpacityFourText(1.0);
    setOpacityFiveText(0);
    setOpacitySixText(0);
    setInfo(
      "HyperJump is led by a DAO (Decentralized Autonomus Organization). Some community members are anonymous and some are doxxed."
    );
  };

  const handleFiveClick = () => {
    setOpacityOne(0.25);
    setOpacityTwo(0.25);
    setOpacityThree(0.25);
    setOpacityFour(0.25);
    setOpacityFive(1.0);
    setOpacitySix(0.25);
    setOpacityOneText(0);
    setOpacityTwoText(0);
    setOpacityThreeText(0);
    setOpacityFourText(0);
    setOpacityFiveText(1.0);
    setOpacitySixText(0);
    setInfo(
      "HyperJump aims to combine gaming and decentralized finance together. We have launched a number of games and more will come in the future"
    );
  };

  const handleSixClick = () => {
    setOpacityOne(0.25);
    setOpacityTwo(0.25);
    setOpacityThree(0.25);
    setOpacityFour(0.25);
    setOpacityFive(0.25);
    setOpacitySix(1.0);
    setOpacityOneText(0);
    setOpacityTwoText(0);
    setOpacityThreeText(0);
    setOpacityFourText(0);
    setOpacityFiveText(0);
    setOpacitySixText(1.0);
    setInfo(
      "HyperJump is a trusted project by both code and community. Multiple project have participated in ecosystem."
    );
  };

  return (
    <Flex className="selectionsMobile">
      <Box className="buttonsSelectionsMobile ">
        <Box
          as="button"
          style={{ opacity: opacityOne }}
          onClick={handleOneClick}
        >
          <Image src={ecosystem} m="0 10px" w="35px" />{" "}
        </Box>

        <Box
          as="button"
          style={{ opacity: opacityTwo }}
          onClick={handleTwoClick}
        >
          <Image src={safu} m="0 10px" w="35px" />
        </Box>

        <Box
          as="button"
          style={{ opacity: opacityThree }}
          onClick={handleThreeClick}
        >
          <Image src={multi} m="0 10px" w="35px" />{" "}
        </Box>

        <Box
          as="button"
          style={{ opacity: opacityFour }}
          onClick={handleFourClick}
        >
          <Image src={community} m="0 10px" w="35px" />
        </Box>

        <Box
          as="button"
          style={{ opacity: opacityFive }}
          onClick={handleFiveClick}
        >
          <Image src={gaming} m="0 10px" w="35px" />
        </Box>

        <Box
          as="button"
          style={{ opacity: opacitySix }}
          onClick={handleSixClick}
        >
          <Image src={partnership} m="0 10px" w="35px" />
        </Box>
      </Box>

      <Box className="listSelectionsMobile">
        {" "}
        <p style={{ opacity: opacityOneText }}>
          <span className="listBlueMobile">ALL-IN-ONE </span> ECOSYSTEM
        </p>{" "}
        <p style={{ opacity: opacityTwoText }}>
          HYPER-<span className="listBlueTwoMobile">SAFU</span>
        </p>
        <p style={{ opacity: opacityThreeText }}>
          <span className="listBlueTwoMobile">MULTI-CHAIN </span> PROJECT
        </p>
        <p style={{ opacity: opacityFourText }}>
          COMMUNITY DRIVEN <span className="listBlueTwoMobile">(DAO)</span>
        </p>
        <p style={{ opacity: opacityFiveText }}>
          {" "}
          GAMING RE<span className="listBlueTwoMobile">DEFI</span>NED
        </p>
        <p style={{ opacity: opacitySixText }}>
          {" "}
          COUNTLESS <span className="listBlueThreeMobile">PARTNERSHIPS </span>
        </p>
      </Box>

      <Box className="panelInfoMobile">
        <Box className="backSelectionsMobile">
          <p className="textSelectionsMobile"> {item} </p>
        </Box>

        <Box className=" botonesPanelInfoMobile">
          <Button
            as="a"
            href="https://ftm.hyperjump.app/"
            target="_blank"
            colorScheme="blue"
            className="buttonLengend"
          >
            {" "}
            Launch dApps <ExternalLinkIcon w={6} h={6} m="0 0 4px 5px" />{" "}
          </Button>
          <Button
            as="a"
            href="https://docs.hyperjump.app/#/essentials/starmap"
            target="_blank"
            colorScheme="blue"
            className="buttonLengend"
          >
            What's coming next
          </Button>
        </Box>
      </Box>
    </Flex>
  );
}
