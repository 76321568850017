import React from "react";
import { Box } from "@chakra-ui/react";
/* import { Divider } from "./Divider.jsx"; */
import { TitleTokens } from "./TitleTokens.jsx";
import { Divider } from "./Divider.jsx";

import { Panel } from "./Panel.jsx";

export function Tokens() {
  return (
    <Box
      h="100%"
      bg="brand.secondary"
      id="tokens"
      className="tokens"
      
    >
      <TitleTokens /> 
      <Panel />
      <Divider /> 
    </Box>
  );
}
