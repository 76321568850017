import React, { useState, useEffect } from "react";
import { Box, Image, Button } from "@chakra-ui/react";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import panelInfo from "../data/dataTokens.js";
import { ethers } from "ethers";

export function DataPanel(token) {
  const [chainId, setChainId] = useState(250);

  // set chainId
  useEffect(() => {
    async function getNetwork() {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const { chainId } = await provider.getNetwork();
      setChainId(chainId);
    }

    getNetwork();
  }, [chainId]);

  //console.log("datapanel token", token.token);
  const selectedToken = panelInfo[token.token];
  //console.log(panelInfo, selectedToken, token);
  return (
    <Box key={token.id} className="containerPanel">
      <Image
        src={selectedToken.back}
        atl={selectedToken.alt}
        className="panel"
      />

      <Box className="containerInfo">
        <Box className="infoPanel">
          <h1>{selectedToken.title}</h1>
          <p>{selectedToken.description}</p>
        </Box>

        <Box className="panelSideTwo">
          <Box className="linksPanel">
            <Button
              as="a"
              href={selectedToken.linkTwo[chainId === 250 ? "ftm" : "bsc"]}
              colorScheme="blue"
              className="buttonLengend"
            >
              {selectedToken.infoBotOne}
              <ExternalLinkIcon w={6} h={6} m="0 0 4px 5px" />
            </Button>
            <Button
              as="a"
              href={selectedToken.linkOne}
              colorScheme="blue"
              className="buttonLengend"
            >
              {selectedToken.infoBotTwo}
              <ExternalLinkIcon w={6} h={6} m="0 0 4px 5px" />
            </Button>
            <Image
              src={selectedToken.market}
              atl={token.alt}
              w="30px"
              h="30px"
            />
            <Image
              src={selectedToken.gecko}
              atl={token.alt}
              w="30px"
              h="30px"
            />
          </Box>

          <Box className="listPanel">
            <Box className="containerList">
              <Box className="boxImage">
                <Image src={selectedToken.iconOne} atl={token.alt} />
              </Box>
              <Box className="boxInfo">
                <h1>{selectedToken.titleOne}</h1>
                <p>{selectedToken.descriptionOne}</p>
              </Box>
            </Box>

            <Box className="containerList">
              <Box className="boxImage">
                <Image src={selectedToken.iconTwo} atl={token.alt} />
              </Box>
              <Box className="boxInfo">
                <h1>{selectedToken.titleTwo}</h1>
                <p>{selectedToken.descriptionTwo}</p>
              </Box>
            </Box>

            <Box className="containerList">
              <Box className="boxImage">
                <Image src={selectedToken.iconThree} atl={token.alt} />
              </Box>
              <Box className="boxInfo">
                <h1>{selectedToken.titleThree}</h1>
                <p>{selectedToken.descriptionThree}</p>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
