import { extendTheme } from "@chakra-ui/react";

// 2. Extend the theme to include custom colors, fonts, etc
const colors = {
  brand: {
    primary: "#3FC4E0",
    secondary: "#0071A0",
    accent: "#A62B30",
    texto: "#ffffff",
    backDark: "#121212",
  },
}
export const theme = extendTheme({ colors })

