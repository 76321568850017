import React from "react";
import { Box, Image, Button } from "@chakra-ui/react";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import panelInfo from "../data/dataTokensMobile.js";

export function DataPanelMobile(token) {
  //console.log("datapanel token", token.token);
  const selectedToken = panelInfo[token.token];
  //console.log(panelInfo, selectedToken, token);
  return (
    <Box key={token.id} className="containerPanel">
      <Image
        src={selectedToken.backM}
        atl={selectedToken.alt}
        className="panelMobile" 
      />

      <Box className="containerInfoMobile">
        <Box className="infoPanelMobile">
          <h1>{selectedToken.title}</h1>
          <p>{selectedToken.description}</p>
        </Box>

        <Box className="panelSideTwoMobile">
          <Box className="listPanelMobile">
            <Box className="containerList">
              <Box className="boxImage">
                <Image src={selectedToken.iconOne} atl={token.alt} />
              </Box>
              <Box className="boxInfo">
                <h1>{selectedToken.titleOne}</h1>
                <p>{selectedToken.descriptionOne}</p>
              </Box>
            </Box>

            <Box className="containerList">
              <Box className="boxImage">
                <Image src={selectedToken.iconTwo} atl={token.alt} />
              </Box>
              <Box className="boxInfo">
                <h1>{selectedToken.titleTwo}</h1>
                <p>{selectedToken.descriptionTwo}</p>
              </Box>
            </Box>

            <Box className="containerList">
              <Box className="boxImage">
                <Image src={selectedToken.iconThree} atl={token.alt} />
              </Box>
              <Box className="boxInfo">
                <h1>{selectedToken.titleThree}</h1>
                <p>{selectedToken.descriptionThree}</p>
              </Box>
            </Box>
            <Box className="linksPanelMobile">
              <Box display="flex">
                <Button
                  as="a"
                  href={selectedToken.linkOne}
                  colorScheme="blue"
                  className="buttonLengend"
                >
                  {selectedToken.infoBotOne}{" "}
                  <ExternalLinkIcon w={6} h={6} m="0 0 4px 5px" />{" "}
                </Button>
                <Button
                  as="a"
                  href={selectedToken.linkOne}
                  colorScheme="blue"
                  className="buttonLengend"
                >
                  {selectedToken.infoBotTwo}{" "}
                  <ExternalLinkIcon w={6} h={6} m="0 0 4px 5px" />{" "}
                </Button>
              </Box>
              <Box display="flex" p="1rem" w="100%" justifyContent="flex-end">
                <Image src={selectedToken.market} atl={token.alt} w="30px" h="30px" />
                <Image src={selectedToken.gecko} atl={token.alt} w="30px" h="30px" />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
