import React, { useState } from "react";
import { Box, Flex } from "@chakra-ui/react";
import { DataPanel } from "./DataPanel.jsx";
import { DataPanelMobile } from "./DataPanelMobile.jsx";
import { ButtonsPanel } from "./ButtonsPanel.jsx";
import { ButtonsPanelMobile } from "./ButtonsPanelMobile.jsx";

export function Panel() {
  const [token, setToken] = useState(["hypr"]);

  return (
    <Flex className="boxSlider">
      <Box zIndex={1} className="elementsPanel">
        <Box display={["none", "none", "none", "flex"]}>
          <ButtonsPanel setToken={setToken} />
        </Box>

        <Box display={["none", "none", "none", "flex"]}>
          <DataPanel token={token} />
        </Box>
      </Box>
      <Box zIndex={1} className="elementsPanelMobile">
        <Box display={["flex", "flex", "flex", "none"]}>
          <ButtonsPanelMobile setToken={setToken} />
        </Box>

        <Box display={["flex", "flex", "flex", "none"]}>
          <DataPanelMobile token={token} />
        </Box>
      </Box>
    </Flex>
  );
}
