import React from "react";
import { Box, Image, Flex } from "@chakra-ui/react";
import divider from "../images/divider.svg";
import dividerMobile from "../images/dividerMobile.svg";

export function Divider() {
  return (
    <Flex className="dividerTokens">
      <Box w="100%" pos="absolute" zIndex={1}>
        <Image src={divider} className="imageDivider" display={["none", "none", "flex", "flex" ]} />
      </Box>

      <Box w="100%" pos="absolute" zIndex={1}  display={["flex", "flex", "none", "none" ]}>
        <Image src={dividerMobile} className="imageDivider" />
      </Box>
    </Flex>
  );
}
