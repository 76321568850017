import React  from "react";
import { Box } from "@chakra-ui/react";
import { TitleHyper } from "./TitleHyper.jsx";
import { Selections } from "./Selections.jsx";
import { SelectionsMobile } from "./SelectionsMobile.jsx";


export function Hyper() {
 

  return (
    <Box h="100%" w="100%" position="relative" id="hyper" className="backWhy" >
      <TitleHyper />
      <Box display={["none", "none", "none", "flex"]}>
      <Selections/>
      </Box>

      <Box display={["flex", "flex", "flex", "none"]}>
      <SelectionsMobile/>
      </Box>
    </Box>
  );
}
