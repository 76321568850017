import React from "react";
import videoWebm from "../media/hyperVideo.webm";
import videoMp4 from "../media/hyperVideo.mp4";

export function BackHome() {
  return (
    <div className="cover-container">
      <video className="video" autoPlay loop muted playsInline>
        <source src={videoWebm} type="video/webm" />
        <source src={videoMp4} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
}
